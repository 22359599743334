import axios from "axios";
export const ACCESS_TOKEN_NAME = "access_token_pandora";
export const REFRESH_TOKEN_NAME = "refresh_token_pandora";

const backendUri = "http://staging-futuro.proximasistemas.com.mx";
const baseURL = `${backendUri}/api/v1/pandora`;

export const remoteImages = `${backendUri}`;

const ACCESS_TOKEN = localStorage.getItem(ACCESS_TOKEN_NAME);

export const backendApi = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  },
});
